export default function HomeRedirect() {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
        window.location = '/gb/en'
    }
    return null
}

// import React from "react"
// import { graphql } from "gatsby"
// import get from 'lodash/get'

// import Layout from '../components/Layout'


// class RootIndex extends React.Component {
//   render() {

//     const pageData = get(this, 'props.data.allContentfulPpcPageData.nodes')

//     return (
//       <div>
//         <Layout pageData={pageData} />
//       </div>
//     )
//   }
// }

// export default RootIndex

// export const pageQuery = graphql`
//   query pageDataQuery{
//     contentfulPpcPageData(countryCode: { eq: "GB" }, languageCode: { eq: "EN" }) {
//       metaAuthor
//       metaTitle
//       metaDescription
//       metaKeywords
//       appleStoreImage {
//         url
//         description
//       }
//       appleStoreLink
//       countryCode
//       countryName
//       downloadAndSaveSubtitle
//       downloadAndSaveTitle
//       downloadTheFreeAppText
//       downloadAndSaveImage {
//         url
//         description
//       }
//       downloadAndSaveVideo {
//         url
//         file {
//           url
//         }
//       }
//       faqs {
//         id
//         title
//         content {
//           raw
//         }
//         response {
//           id
//           content {
//             content
//           }
//         }
//       }
//       findACarWashText
//       findACarWashLink
//       findYourNearestImage {
//         url
//         description
//       }
//       findYourNearestButtonLink
//       findYourNearestButtonText
//       findYourNearestSubtitle
//       findYourNearestTitle
//       footerLink {
//         title
//         url
//         id
//         displayedName
//       }
//       footerLogo {
//         url
//         description
//       }
//       googlePlayImage {
//         url
//         description
//       }
//       googlePlayLink
//       headerLogo {
//         url
//         description
//       }
//       imoCarWashCompanyName
//       keepUpWithButtonText
//       keepUpWithButtonLink
//       keepUpWithHeading
//       keepUpWithBackgroundImage {
//         url
//         description
//       }
//       keepUpWithBackgroundImageMobile {
//         url
//         description
//       }
//       languageCode
//       loveYourCarImage1 {
//         url
//         description
//       }
//       loveYourCarImage2 {
//         url
//         description
//       }
//       loveYourCarImage3 {
//         url
//         description
//       }
//       loveYourCarSubtitle1
//       loveYourCarSubtitle2
//       loveYourCarSubtitle3
//       loveYourCarTitle
//       loveYourCarTitle1
//       loveYourCarTitle2
//       loveYourCarTitle3
//       saveTimeSaveMoneyText
//     }
//   }
// `
